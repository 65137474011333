<template>
  <div
    class="tw-w-full tw-relative tw-block tw-overflow-hidden"
    :style="{ height }"
  >
    <slot v-if="loading" name="placeholder">
      <div
        class="tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-text-zinc-300 tw-text-xl"
        :class="placeholderBgClass"
      >
        <i class="las la-hourglass" />
      </div>
    </slot>
    <slot v-else-if="imageError" name="error">
      <div
        class="tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-text-zinc-300"
        :title="title"
      >
        <div class="tw-text-xl">
          <i v-if="file" class="las la-times-circle" />
          <i v-else class="las la-image" />
        </div>
        <div v-if="!file" v-show="heightPx > 200">
          {{ $t('releases.message.noPreview') }}
        </div>
        <div v-if="previewError" v-show="heightPx > 200">
          {{ previewError }}
        </div>
      </div>
    </slot>
    <img
      v-else
      :key="src"
      class="tw-w-full tw-h-full"
      :class="imgClass"
      :alt="title"
      :src="src"
      :title="title"
      @error="handleError"
    />
  </div>
</template>

<script>
export default {
  name: 'MfImage',
  props: {
    height: {
      type: String,
      default: '200px',
    },
    title: String,
    file: {
      type: String,
      required: true,
    },
    refresh: Date,
    placeholderBgClass: {
      type: String,
      default: 'tw-bg-zinc-100',
    },
    imgClass: {
      type: [String, Array, Object],
      default: 'tw-object-contain',
    },
  },
  data() {
    return {
      previewError: false,
      imageError: false,
      loaded: null,
      loading: true,
      src: '',
    }
  },
  computed: {
    heightPx() {
      return this.height.match(/\d+/)[0]
    },
  },
  watch: {
    file(val) {
      if (val) this.loadPreview(val)
    },
    refresh(val) {
      if (val && this.loaded && val > this.loaded) {
        this.loadPreview(this.file, true)
      }
    },
  },
  mounted() {
    this.loadPreview()
  },
  methods: {
    loadPreview(file = this.file, force = false) {
      if (!file) {
        this.loaded = null
        this.loading = false
        this.imageError = true
        return
      }
      this.src = '#'
      this.loading = true
      this.imageError = false
      this.previewError = false
      if (force) {
        this.$store.commit('storageUrls/remove', file)
        this.$store.commit('storageUrls/removeReading', file)
      }
      this.$store
        .dispatch('storageUrls/get', file)
        .then((src) => {
          this.src = src
          this.loaded = new Date()
        })
        .catch((error) => {
          this.previewError =
            error.code === 'storage/object-not-found'
              ? this.$t('message.storageErrorNotFound')
              : this.$t('message.storageErrorGeneric')
          this.imageError = true
        })
        .finally(() => (this.loading = false))
    },
    handleError() {
      this.imageError = true
    },
  },
}
</script>
