import { isNil, get, cloneDeep } from 'lodash'
import axios from 'axios'
import config from '@/config'
import i18n from '@/plugins/i18n'
import Database from '../../collections-db'

export default {
  /**
   * Fetch list and listen for changes
   * @param context
   * @returns {Promise<void>}
   */
  getAll: async ({ commit, state, getters, rootState }) => {
    // already listening to all documents?
    if (getters.isListeningToAll) {
      return Promise.reject(Error('already listening to all collections'))
    }

    const setup = []

    const db = new Database(commit)

    const { user } = rootState.authentication

    if (user.organization === 'mcs') {
      if (!state.listenerAll.organization) {
        setup.push(
          db
            .queryAndListen([], 1000, false, false, 'Organization')
            .then((unsubscribe) =>
              commit('addListenerAll', {
                source: 'organization',
                unsubscribe,
              })
            )
        )
      }
    } else {
      // listen for all owned docs
      if (!state.listenerAll.owner) {
        setup.push(
          db
            .queryAndListen(
              [['owner', '==', user.id]],
              100,
              false,
              false,
              'Owner'
            )
            .then((unsubscribe) =>
              commit('addListenerAll', {
                source: 'owner',
                unsubscribe,
              })
            )
        )
      }

      // listen for shared with organization docs
      if (!state.listenerAll.organization) {
        setup.push(
          db
            .queryAndListen(
              [[`organizations.${user.organization}`, '==', true]],
              100,
              false,
              false,
              'Organization'
            )
            .then((unsubscribe) =>
              commit('addListenerAll', {
                source: 'organization',
                unsubscribe,
              })
            )
        )
      }

      // listen for shared with user docs
      if (!state.listenerAll.user) {
        setup.push(
          db
            .queryAndListen(
              [[`users.${user.id}`, '==', true]],
              100,
              false,
              false,
              'User'
            )
            .then((unsubscribe) =>
              commit('addListenerAll', {
                source: 'user',
                unsubscribe,
              })
            )
        )
      }
    }

    await Promise.all(setup)
    return Promise.resolve()
  },

  /**
   * Set-up listeners for content and release references
   * @param context
   * @param id
   * @returns {Promise<unknown[]>}
   */
  getReferences: async ({ getters, rootGetters, commit, dispatch }, id) => {
    // collect async tasks
    const tasks = []

    const isSupplier = rootGetters['authentication/isSupplier']
    const filterOwner = isSupplier ? null : 'organization'

    if (!getters.hasListenerContents(id)) {
      // set-up listener for contents
      commit('contents/filterType', null, { root: true })
      commit('contents/filterOwner', filterOwner, { root: true })
      commit('contents/filterCollection', id, { root: true })
      commit('loadingContents', true)
      tasks.push(
        dispatch('contents/newList', null, { root: true }).finally(() =>
          commit('loadingContents', false)
        )
      )
    }

    if (!getters.hasListenerReleases(id)) {
      // set-up listener for releases
      commit('releases/filterType', null, { root: true })
      commit('releases/filterOwner', filterOwner, { root: true })
      commit('releases/filterCollection', id, { root: true })
      commit('loadingReleases', true)
      tasks.push(
        dispatch('releases/newList', null, { root: true }).finally(() =>
          commit('loadingReleases', false)
        )
      )
    }

    return Promise.all(tasks)
  },

  /**
   * detach listener for all documents
   * @param state
   * @param commit
   * @returns {Promise<void>}
   */
  unsubscribeListenerAll: async ({ state, commit }) => {
    Object.entries(state.listenerAll).forEach(([name, unsubscribe]) => {
      unsubscribe()
      commit('removeListenerAll', name)
    })
  },

  /**
   * Fetch one and set as editItem
   * @param context
   * @param docId
   * @returns {Promise<void>}
   */
  loadEditItem: async ({ commit, getters }, docId) => {
    if (isNil(docId)) throw Error('Parameter docId is required')
    const item = getters.get(docId)
    if (!item) throw Error(`Collection ${docId} not found!`)
    commit('editItem', cloneDeep(item))
    commit('editIsNew', false)
  },

  /**
   * Call loadEditItem and set as new item
   * @param context
   * @param id
   * @returns {Promise<void>}
   */
  loadEditItemCopy: async ({ state, commit, dispatch, rootState }, id) => {
    await dispatch('loadEditItem', id)
    commit('updateEditItem', { path: 'id', value: undefined })
    commit('updateEditItem', {
      path: 'owner',
      value: rootState.authentication.user.id,
    })
    commit('updateEditItem', {
      path: 'name',
      value: `${state.editItem.name || ''}${i18n.t('placeholder.copySuffix')}`,
    })
    commit('editIsNew', true)
  },

  /**
   * Set editItem to empty item template
   * @param commit
   * @param rootState
   * @returns {Promise<void>}
   */
  resetEditItem: ({ commit, rootState }) => {
    const item = {
      owner: rootState.authentication.user.id,
      organization: rootState.authentication.claims.org,
    }
    commit('editItem', item)
    commit('editIsNew', true)
  },

  /**
   * create or update item in database
   * @param context
   * @param doc
   */
  set: async ({ commit }, doc) => {
    const db = new Database(commit)
    const id = !isNil(doc.id) && doc.id ? doc.id : null
    const item = { ...doc }
    delete item.id
    return db.create(item, id)
  },

  /**
   * Update item in database
   * @param context
   * @param doc
   * @returns {Promise<*>}
   */
  update: async ({ commit }, doc) => {
    if (isNil(doc.id)) throw new Error('id is required')
    const db = new Database(commit)
    return db.update(doc)
  },

  create: async (
    { rootState, rootGetters, dispatch },
    { organization, parentId }
  ) => {
    const item = {
      name: i18n.t('collections.field.nameDefault'),
      organization: rootState.authentication.claims.org,
      owner: rootState.authentication.user.id,
      type: 'generic',
    }
    if (rootGetters['authentication/isAdminOrSupplier'] && organization) {
      item.organization = organization
      if (parentId) {
        item.parentId = parentId
      }
    }
    return dispatch('set', item)
  },

  /**
   * Create a new property and reset property name input
   */
  commitEditItem: async ({ dispatch, state, commit }) => {
    if (state.editItem === null) return false
    const doc = state.editItem
    const newDoc = await dispatch('set', doc)
    commit('editItem', null)
    // update references
    const collectionId = newDoc.id
    const references = []
    if (newDoc.contents) {
      Object.keys(newDoc.contents).forEach((contentId) =>
        references.push(
          dispatch(
            'contents/addCollection',
            { id: contentId, collectionId },
            { root: true }
          )
        )
      )
    }
    if (newDoc.releases) {
      Object.keys(newDoc.releases).forEach((releaseId) =>
        references.push(
          dispatch(
            'releases/addCollection',
            { id: releaseId, collectionId },
            { root: true }
          )
        )
      )
    }
    await Promise.all(references).catch(console.error)
    return newDoc
  },

  /**
   * Delete item from database
   * @param context
   * @param collectionId
   * @returns {Promise<void>}
   */
  delete: async ({ commit, getters, dispatch }, collectionId) => {
    if (!collectionId) throw Error('Parameter collectionId is required')
    const doc = getters.get(collectionId)
    if (!doc) throw Error(`Collection ${collectionId} not found`)
    // remove references to this collection
    const contentIds = doc.contents ? Object.keys(doc.contents) : []
    const releaseIds = doc.releases ? Object.keys(doc.releases) : []
    const tasks = [
      ...contentIds.map((id) =>
        dispatch(
          'contents/removeCollection',
          { id, collectionId },
          { root: true }
        )
      ),
      ...releaseIds.map((id) =>
        dispatch(
          'releases/removeCollection',
          { id, collectionId },
          { root: true }
        )
      ),
    ]
    // remove item itself
    const db = new Database(commit)
    tasks.push(db.delete(collectionId))
    // wait for all tasks to complete
    await Promise.all(tasks)
  },
  publicationsFromContents: async (
    { dispatch, commit },
    {
      collectionId,
      templateId,
      language = null,
      mode = null,
      filterActive = true,
      mergeVariants = false,
      filterContents = null,
    }
  ) => {
    const token = await dispatch('authentication/getToken', false, {
      root: true,
    })
    return axios({
      url: config.api.graphQl,
      method: 'post',
      headers: { authorization: token ? `Bearer ${token}` : '' },
      data: {
        operationName: 'CollectionPublicationsFromContents',
        variables: {
          collectionId,
          templateId,
          language,
          mode,
          filterActive,
          mergeVariants,
          filterContents,
        },
        query: `
mutation CollectionPublicationsFromContents(
  $collectionId: ID!,
  $templateId: ID!,
  $language: String,
  $mode: CollectionGeneratePublicationMode,
  $filterActive: Boolean,
  $mergeVariants: Boolean,
  $filterContents: [ID!]
) {
  collectionPublicationsFromContents(
    id: $collectionId,
    templateId: $templateId,
    language: $language,
    mode: $mode,
    filterActive: $filterActive,
    mergeVariants: $mergeVariants,
    filterContents: $filterContents
  )
}
`,
      },
    }).then((result) => {
      if (result.data.errors && result.data.errors[0]) {
        throw Error(result.data.errors[0].message)
      }
      commit('showType', 'releases')
      return get(result, 'data.data.collectionPublicationsFromContents', null)
    })
  },
  downloadReleases: async (
    { dispatch, getters },
    {
      id,
      releaseIds,
      pdf = true,
      digital = true,
      png = false,
      pngSelect = null,
    }
  ) => {
    const token = await dispatch('authentication/getToken', false, {
      root: true,
    })
    const collection = getters.get(id)
    const fileNames = (collection && collection.releaseFileNames) || null
    const result = await axios({
      url: config.api.graphQl,
      method: 'post',
      headers: { authorization: token ? `Bearer ${token}` : '' },
      data: {
        operationName: 'DownloadReleases',
        variables: {
          input: { id, releaseIds, fileNames, pdf, digital, png, pngSelect },
        },
        query: `
query DownloadReleases($input: CollectionDownloadReleasesInput!) {
  collectionDownloadReleases2(input: $input)
}
`,
      },
    })
    if (result.data.errors && result.data.errors[0]) {
      throw Error(result.data.errors[0].message)
    }
    return get(result, 'data.data.collectionDownloadReleases2', null)
  },
  downloadContents: async ({ dispatch }, collectionId) => {
    const token = await dispatch('authentication/getToken', false, {
      root: true,
    })
    const result = await axios({
      url: config.api.graphQl,
      method: 'post',
      headers: { authorization: token ? `Bearer ${token}` : '' },
      data: {
        operationName: 'DownloadContents',
        variables: { collectionId },
        query: `
query DownloadContents($collectionId: ID!) {
  collectionDownloadContents(id: $collectionId)
}
`,
      },
    })
    if (result.data.errors && result.data.errors[0]) {
      throw Error(result.data.errors[0].message)
    }
    return get(result, 'data.data.collectionDownloadContents', null)
  },

  updateReleaseLabels: async (
    { dispatch, rootGetters, rootState },
    { collectionId, language }
  ) => {
    const { selection } = rootState.releases
    const filterReleases = rootGetters['releases/listFiltered']
      .filter((item) => !selection.length || selection.includes(item.id))
      .map((item) => item.id)
    const token = await dispatch('authentication/getToken', false, {
      root: true,
    })
    const result = await axios({
      url: config.api.graphQl,
      method: 'post',
      headers: { authorization: token ? `Bearer ${token}` : '' },
      data: {
        operationName: 'UpdateLabels',
        variables: { collectionId, language, filterReleases },
        query: `
mutation UpdateLabels($collectionId: ID!, $language: String, $filterReleases: [ID!]) {
  collectionUpdateReleaseLabels(id: $collectionId, language: $language, filterReleases: $filterReleases)
}
`,
      },
    })
    if (result.data.errors && result.data.errors[0]) {
      throw Error(result.data.errors[0].message)
    }
    return get(result, 'data.data.collectionUpdateReleaseLabels', null)
  },
}
