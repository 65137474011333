export default {
  all: {},

  // list items
  list: [],
  listConstraints: [],
  loading: null,

  // query/listener filters
  filterCollection: null,
  filterOwner: 'organization', // ... supplier
  filterType: null, // 'print', 'digital'

  // display filters
  filterArtworkErrors: false,
  filterStatus: null,

  // list item selection
  selection: [],
  selectionAdded: false,
  selectionLock: false,

  // editing
  active: null,
  editItem: null,
  editIsNew: true,
  deletionPending: {},
  updatePending: false,
  editDataLink: null,

  // publication's releases list
  pubId: null,
  pubList: [],

  // listeners
  listenerSingle: {},
  listenerAll: null,
  listenerPub: null,

  // display settings
  layout: 'grouped', // grouped, cards, table
  zoom: 5,
  scrollPosition: {
    cards: {},
    grouped: {},
    table: {},
  },
  dpiWarning: 300,
  previewPage: 1,
}
