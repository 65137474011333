import moment from 'moment'
import i18n from '@/plugins/i18n'
import shortId from 'Shared/misc/short-id'

export default {
  /* get single item */
  get: (state) => (id) => (id && state.all[id]) || null,
  list: (state, getters, rootState, rootGetters) =>
    state.list
      .map((id) => getters.get(id))
      .filter(
        (item) =>
          !item.hidden || rootGetters['authentication/isAdminOrSupplier']
      ),
  active: (state, getters) => (state.active && getters.get(state.active)) || {},
  byCollection: (state, getters) => (id) =>
    getters.list.filter((dpl) => dpl.collectionId === id),
  addName: (state, getters, rootState, rootGetters) => (colOrOrderId) => {
    const nameParts = []
    let hasName = false
    if (colOrOrderId) {
      const col = rootGetters['collections/get'](colOrOrderId)
      const order = rootGetters['orders/get'](colOrOrderId)
      const org = rootGetters['organizations/get'](
        (col && col.organization) || (order && order.owner)
      )
      if (col) {
        if (col.project) {
          nameParts.push(col.project)
        } else if (org && org.name) {
          nameParts.push(org.name)
        }
        if (col.name) {
          nameParts.push(col.name)
          hasName = true
        }
      } else if (order) {
        nameParts.push(
          i18n.t('orders.title.showId', { id: shortId(colOrOrderId) })
        )
        hasName = true
        if (org && org.name) {
          nameParts.push(org.name)
        }
      }
    }
    if (!hasName) {
      nameParts.push(i18n.t('deployments.title.add'))
    }
    nameParts.push(moment().format('L LT'))
    return nameParts.join(' ')
  },
  expired: () => (doc) => {
    if (!doc.expiration) return false
    return doc.expiration < Date.now()
  },
  timeout: () => (doc, now) => {
    if (['done', 'error'].includes(doc.status)) return false
    if (!now) now = Date.now()
    return now - doc.createTimestamp >= 600000
  },
  downloadable: (state, getters) => (id) => {
    const doc = getters.get(id)
    if (!doc || doc.status !== 'done') return false
    return !getters.expired(doc)
  },
  deletable: (state, getters, rootState, rootGetters) => (id) => {
    const doc = getters.get(id)
    if (!doc || !doc.status) return false
    if (doc.status === 'open') return false
    if (doc.status === 'pending' && !getters.timeout(doc)) return false
    if (rootGetters['authentication/isAdmin']) return true
    if (rootGetters['authentication/isSupplier']) return true
    if (!doc.organization) return false
    if (doc.organization !== rootGetters['authentication/organizationId'])
      return false
    const docUser = (doc.createUser && doc.createUser.id) || null
    return docUser && docUser === rootGetters['authentication/currentUser'].uid
  },
  deletePending: (state) => (id) => (id && state.deletePending[id]) || false,
  logFiltered: (state, getters) => (id, level) => {
    const doc = getters.get(id)
    if (!doc || !doc.exportLog) return []
    return doc.exportLog.filter((el) => {
      if (!el.level) return false
      if (!level) return el.level !== 'debug'
      return el.level === level
    })
  },
  hasCustomReleasePdf: (state) => (releaseId) =>
    !!state.customReleasePdfs.find((file) => file === `${releaseId}.pdf`),
}
